// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page images
import infoIconBackground from "assets/img/icons/flag.svg"
// page styles
import sponsorApplicationAboutStyle from "assets/jss/material-kit-pro-react/views/sponsorApplicationPageSections/sponsorApplicationAboutStyle.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import React from "react"

const useStyles = makeStyles(sponsorApplicationAboutStyle)

export default function SponsorApplicationAbout() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <img
            className={classes.sponsorApplicationAboutIcon}
            src={infoIconBackground}
            alt="Information Icon"
          />
          <GridItem
            xs={12}
            sm={10}
            className={classes.sponsorApplicationAboutItem}
          >
            <h2 className={classes.title}>
              Chilimania is a Community-Wide Fundraiser
            </h2>
            <p className={classes.description}>
              Many people recognize Chilimania as an annual, fun weekend event
              in Edgerton. What some may not know is that Chilimania, Inc. is a
              locally based non-profit organization, staffed by an enthusiastic,
              all volunteer membership. Our members strive to do more each year
              to keep Edgerton and its wonderful community in the spotlight. We
              provide charity through chili!
              <br />
              <br />
              Generous donors like you are the key to our success and make it
              possible for us to provide local students with college
              scholarships, assist the Edgerton School District with various
              programs, fund local charitable organizations and promote the
              civic and business organizations of the community. In May 2019,
              Chilimania awarded ten (10) scholarships to college-bound Edgerton
              High School seniors, each in the amount of $2,500!
              <br />
              <br />
              We hope that we can count on your support to promote the downtown
              business community and raise funds for our area youth.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
