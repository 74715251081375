import {
  container,
  description,
  title,
} from "assets/jss/material-kit-pro-react.jsx"

const sponsorApplicationAboutStyle = (theme) => ({
  sponsorApplicationAboutButton: {
    margin: "1rem",
  },
  sponsorApplicationAboutIcon: {
    position: "absolute",
    opacity: ".075",
    right: "5%",
    maxHeight: "400px",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      right: "auto",
      maxWidth: "92%",
      opacity: ".05",
    },
  },
  sponsorApplicationAboutItem: {
    "@media (max-width: 599px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "83.333333%",
      flexBasis: "83.333333%",
    },
    "@media (max-width: 800px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "0",
  },
  container,
  description,
  section: {
    padding: "80px 0",
    textAlign: "center",
  },
})

export default sponsorApplicationAboutStyle
