// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page images
// page styles
import sponsorApplicationInformationStyle from "assets/jss/material-kit-pro-react/views/sponsorApplicationPageSections/sponsorApplicationInformationStyle.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(sponsorApplicationInformationStyle)

export default function SponsorApplicationInformation() {
  const { sponsorApplicationInformationBackground } = useStaticQuery(graphql`
    query getSponsorApplicationInformationPhotos {
      sponsorApplicationInformationBackground: file(
        relativePath: { eq: "backgrounds/sponsor-application-kids.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <GatsbyImage
        className={classes.backgroundImage}
        image={
          sponsorApplicationInformationBackground.childImageSharp
            .gatsbyImageData
        }
        alt="Event Photo"
      />
      <div className={classes.backgroundImageText}>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem
              xs={12}
              sm={10}
              className={classes.sponsorApplicationInformationItem}
            >
              <h2 className={classes.sponsorApplicationInformationTitle}>
                Chilimania values its sponsorship partners
              </h2>
              <p className={classes.sponsorApplicationInformationDescription}>
                Chilimania is widely known as the Midwest’s Largest Chili
                Cook-off, as well as a day of bringing world-class entertainment
                to downtown Edgerton! We pride ourselves on bringing new and
                exciting entertainment to the area every year. Our chili cooks
                come from as many as five different states, and attendees travel
                from all over Wisconsin and Illinois to enjoy the festivities
                and bring business to Edgerton.
                <br />
                <br />
                Over the years, Chilimania Inc., has donated more than $160,000
                to promote the Edgerton business community and other various
                local charity groups and events. Annually we support our local
                school districts and have provided more than 80 high school
                graduates with college scholarships.
                <br />
                <br />
                We strive to do more each year, and with your help we can keep
                the Edgerton community in the spotlight, laying the foundation
                for continued sustainable development. We would like your
                support for the scholarship fund as well as other valuable
                community and business efforts. Below you’ll find a flyer
                showcasing the benefits of becoming a sponsor of Chilimania Inc.
                <br />
                <br />
                Thank you in advance for helping to make Chilimania a success!
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  )
}
